<template>
  <Sticky>
    <div class="field-header">
      <div class="name">生物标记物｜研究信息｜出版信息</div>
      <Icon class-prefix="iconfont" name="shaixuan" size="16" color="#fff" @click='filterHandle' />
    </div>
    <div class="search-type">
      <div class="label">{{ retrieval }}</div>
      <div class="subscribe-wrap" @click="subscribeHandle">
        <div class="subscribe">订阅</div>
      </div>
    </div>
    <div class="bar"></div>
  </Sticky>
  <div class="list-wrap" v-if="!showDashboard">
    <ListHead
      :total="state.count"
      :fullTextShow="state.fullTextShow"
      @on-change="changeHandle"
      @on-click="dashboardHandle"
      @on-full-text="handleFullClick"
    />
    <van-list
      ref="myList"
      v-model:loading="state.loading"
      :finished="state.finished"
      :finished-text="state.list.length ? '没有更多了' : ''"
      offset="20"
      @load="onLoad"
    >
      <Item  v-for="item in state.list" :key="item" :detail="item" @on-click="itemClickHandle" />
    </van-list>
    <Empty v-if="state.list.length <= 0 && state.finished" />
  </div>
  <div v-else>
    <DashBoard :filter="state.filter" :total="state.count" :retrieval="retrieval" @on-click="listHandle" />
  </div>
  <BackTop />
  <Popup
    v-model:show="show"
    round
    position="bottom"
    :style="{ height: '80%' }"
  >
    <Filter
      :list="state.filterList"
      :total="state.total"
      @on-reset="resetHandle"
      @on-ok="okHandle"
      @on-change="filterChange"
    />
  </Popup>
</template>

<script>
import { computed, onActivated, reactive, ref } from 'vue'
import { onBeforeRouteLeave, useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { List, Sticky, Icon, Popup, Toast } from 'vant'
import Empty from '@/components/empty/'
import ListHead from './components/ListHead'
import Item from '@/components/item/'
import BackTop from '@/components/backTop/'
import { getFieldLiteratures, getFieldTags, getAticleCount, subscribeApi } from '@/api/'
import { deleteObjEmptyData, deepCopy } from '@/utils/'
import Filter from './components/Filter'
import DashBoard from '@/views/dashboard/'
export default {
  name: 'fieldList',
  components: {
    'van-list': List,
    Empty,
    Sticky,
    Icon,
    ListHead,
    Item,
    BackTop,
    Popup,
    Filter,
    DashBoard
  },
  setup () {
    // document.title = '文献列表'
    const state = reactive({
      list: [],
      loading: false,
      finished: false,
      count: 0,
      total: 0,
      page: 0,
      publish_year_asc: '',
      impact_factor_asc: '',
      relevance_asc: 0,
      filterList: [],
      filter: null,
      impactFactor: '',
      apiFilter: null,
      filterItem: '',
      fullText: {},
      fullTextShow: false
    })
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const show = ref(false)
    const isFieldEmpty = ref(true)
    const showDashboard = ref(false)
    const myList = ref(null)

    const retrieval = computed(() => {
      if (state.filterItem === '') {
        return route.query.field
      } else {
        return `${route.query.field} - ${state.filterItem}`
      }
    })

    const subscribedHandle = (obj) => {
      state.filterItem = Object.values(obj).map(item => item).join(',').replace(/,/g, ' And ')
    }

    const subscribeHandle = () => {
      const str = state.filterItem === '' ? route.query.field : route.query.field + 'And' + state.filterItem
      const option = {
        field__id__in: route.query.field_id,
        ...deepCopy(state.filter),
        content: str.replace(/\s*/g, '').replace(/And/g, '\tAnd\t'),
        is_subscribed: '1'
      }
      delete option.publish_year__range
      delete option.impact_factor__range
      console.log(str.replace(/\s*/g, '').replace(/And/g, '\tAnd\t'))
      subscribeApi(option).then(res => {
        if (res.code === 200) {
          Toast('订阅成功')
        }
      })
    }

    // 获取筛选项
    getFieldTags({
      field__id: route.query.field_id
    }).then(res => {
      state.apiFilter = res.data
      const bioMarket = {
        title: '生物标记物',
        key: 'bio_markers__bio_marker_id__in',
        isMultiply: true,
        bottom: true,
        lables: res.data.bio_marker.map(item => ({
          name: item.name,
          value: item.id
        }))
      }
      const disease = {
        title: '相关疾病',
        key: 'disease__id__in',
        isMultiply: true,
        lables: res.data.disease.map(item => ({
          name: item.name,
          value: item.id
        }))
      }
      const clinical = {
        title: '临床应用',
        key: 'clinical_application__id__in',
        isMultiply: true,
        lables: res.data.clinical_application.map(item => ({
          name: item.name,
          value: item.id
        }))
      }
      const population = {
        title: '研究人群',
        key: 'population__id__in',
        isMultiply: true,
        bottom: true,
        lables: res.data.population.map(item => ({
          name: item.name,
          value: item.id
        }))
      }
      const language = {
        title: '语言类型',
        key: 'language__in',
        isMultiply: true,
        lables: res.data.language.map(item => ({
          name: item.name,
          value: item.name
        }))
      }
      const articleType = {
        title: '文献类型',
        key: 'article_type__id__in',
        isMultiply: true,
        lables: res.data.article_type.map(item => ({
          name: item.name,
          value: item.id
        }))
      }
      const publishDate = {
        title: '发表年份',
        key: 'publish_year__range',
        type: 'slide',
        lables: res.data.publish_year
      }
      const impactFactor = {
        title: '影响因子',
        key: 'impact_factor__range',
        type: 'slide',
        lables: res.data.impact_factor
      }
      state.impactFactor = res.data.impact_factor
      if (JSON.stringify(deleteObjEmptyData(res.data)) === '{}') {
        isFieldEmpty.value = false
      }
      state.filterList.push(bioMarket, disease, clinical, population, language, articleType, publishDate, impactFactor)
    })
    // 重置变量
    const resetState = () => {
      state.list = []
      state.loading = false
      state.finished = false
      state.page = 0
    }
    // 获取文献列表
    const fetchLiteratures = () => {
      const params = deleteObjEmptyData({
        page: state.page,
        field__id: route.query.field_id,
        relevance_asc: state.relevance_asc,
        publish_year_asc: state.publish_year_asc,
        impact_factor_asc: state.impact_factor_asc,
        ...state.filter,
        ...state.fullText
      })
      getFieldLiteratures(params).then(res => {
        if (res.code === 200) {
          console.log(res)
          state.list.push(...res.data.data.data)
          state.count = res.data.page.count
          state.total = res.data.page.count
          state.loading = false
          if (state.list.length >= state.count) {
            state.finished = true
          }
        }
      })
    }
    // 分页加载
    const onLoad = () => {
      state.page++
      fetchLiteratures()
    }
    // 筛选
    const filterHandle = () => {
      if (isFieldEmpty.value) {
        show.value = true
      }
    }
    // 排序
    const changeHandle = (item) => {
      if (item.key === 'relevance_asc') {
        state.publish_year_asc = ''
        state.impact_factor_asc = ''
        state.relevance_asc = item.value
      } else if (item.key === 'publish_year_asc') {
        state.relevance_asc = ''
        state.impact_factor_asc = ''
        state.publish_year_asc = item.value
      } else {
        state.relevance_asc = ''
        state.publish_year_asc = ''
        state.impact_factor_asc = item.value
      }
      resetState()
      myList.value.check()
    }
    // 筛选重置
    const resetHandle = (current, currentName) => {
      if (current.impact_factor__range === state.impactFactor.join(',')) {
        delete current.impact_factor__range
      }
      if (currentName.language__in) {
        delete currentName.language__in
      }
      if (currentName.article_type__id__in) {
        delete currentName.article_type__id__in
      }
      subscribedHandle(currentName)
      state.filter = current
      // show.value = false
      resetState()
      if (!showDashboard.value) {
        myList.value.check()
      }
    }
    // 筛选确定
    const okHandle = (current, currentName) => {
      if (current.impact_factor__range === state.impactFactor.join(',')) {
        delete current.impact_factor__range
      }
      if (currentName.language__in) {
        delete currentName.language__in
      }
      if (currentName.article_type__id__in) {
        delete currentName.article_type__id__in
      }
      subscribedHandle(currentName)
      state.filter = current
      show.value = false
      resetState()
      if (!showDashboard.value) {
        myList.value.check()
      }
    }
    // 跳转详情
    const itemClickHandle = (item) => {
      router.push(`/detail?id=${item.id}`)
    }
    // 筛选项变化时
    const filterChange = (current) => {
      if (current.impact_factor__range === state.impactFactor.join(',')) {
        delete current.impact_factor__range
      }
      getAticleCount({
        field__id: route.query.field_id,
        ...current
      }).then(res => {
        if (res.code === 200) {
          state.total = res.data.total
        }
      })
    }
    // 切换dashboard
    const dashboardHandle = () => {
      showDashboard.value = true
    }
    // 切换列表模式
    const listHandle = () => {
      showDashboard.value = false
    }

    const handleFullClick = () => {
      state.fullTextShow = !state.fullTextShow
      state.fullText = state.fullTextShow ? { full_text: true } : {}
      resetState()
      myList.value.check()
    }

    onActivated(() => {
      // document.title = '文献列表'
    })

    onBeforeRouteLeave((to, from) => {
      if (to.path === '/dashboardList' || to.path === '/detail') {
        store.commit('catchComponents/GET_CATCHE_COMPONENTS', ['fieldList'])
      } else {
        store.commit('catchComponents/GET_CATCHE_COMPONENTS', [])
      }
    })

    return {
      state,
      onLoad,
      show,
      showDashboard,
      retrieval,
      myList,
      changeHandle,
      filterHandle,
      okHandle,
      resetHandle,
      itemClickHandle,
      filterChange,
      subscribeHandle,
      dashboardHandle,
      listHandle,
      handleFullClick
    }
  }
}
</script>

<style lang="less" scoped>
.field-header {
  height: 40px;
  background: #0C86FE;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .name {
    font-size: 14px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 14px;
  }
}
.search-type {
  padding: 14px 15px 14px 16px;
  background: #FFFFFF;
  display: flex;
  justify-content: space-between;
  .label {
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    line-height: 22px;
    display: flex;
    align-items: center;
  }
  .subscribe-wrap {
    margin-left: 17px;
    display: flex;
    align-items: center;
    .subscribe{
      width: 54px;
      height: 28px;
      border-radius: 4px;
      border: 1px solid #0C86FE;
      font-size: 14px;
      font-weight: 400;
      color: #0C86FE;
      line-height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.bar {
  height: 12px;
  background: #F5F7FA;
}
</style>
